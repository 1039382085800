<template>
  <Portlet
    title="Notes"
    icon="history"
    class="historyList_Operation"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary mr-3', { 'fa-spin' : loading}]"
        icon="sync-alt"
        style="vertical-align: middle; float: right;"
        @click="getHistoryEntries()"
      />
      <toggle-button
        id="viewToggle"
        v-model="noteFilter"
        :labels="{ checked: 'All Notes', unchecked: 'Hide Automatic' }"
        :color="{ checked: 'gray', unchecked: 'green' }"
        :width="115"
        :height="30"
        :font-size="10"
        class="m-0 mr-2"
        @change="changeFilter()"
      />
      <button
        class="btn btn-sm btn-primary"
        @click="show"
      >
        <font-awesome-icon
          class="mr-1"
          icon="pen"
        />
        <span>
          {{ $t('installationDetailOperationView.createNote') }}
        </span>
      </button>
    </template>
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :data-items="kgm_computedGridItems(preparedHistoryComments)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(preparedHistoryComments)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        style="height: auto;"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="nameLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }`"
              class="alt-primary-color"
            >
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </router-link>
          </td>
        </template>
        <template
          slot="dateTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span v-tooltip="dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem))">{{ dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
          </td>
        </template>
        <template
          slot="preTemplate"
          slot-scope="{props}"
        >
          <td
            :class="props.className"
            v-html="kgm_getNestedValue(props.field, props.dataItem)"
          />
        </template>
        <template
          slot="detailTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <button
              class="btn btn-sm btn-primary btn-block"
              @click="openDetailSidebar(props.dataItem)"
            >
              Details
            </button>
          </td>
        </template>
        <div
          slot="messageFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="messageFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a message"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('messageFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="typeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a type"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('typeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="recipientFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="recipientFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a recipient"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('recipientFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>

      <Sidebar
        :show-sidebar="detailSidebarIsVisible"
        :sidebar-width="600"
        @close="closeDetailSidebar"
      >
        <template v-if="detailSidebarIndex != null">
          <table class="defaultTable">
            <colgroup>
              <col width="33.333%">
              <col width="66.666%">
            </colgroup>
            <tbody>
              <tr>
                <th class="bold">
                  Created
                </th>
                <td data-placement="top">
                  <span v-tooltip="dateTime_fromNow(getDetailSidebarComment.created)">{{ getDetailSidebarComment.created | dateTime_dateTime }}</span>
                </td>
              </tr>
              <tr>
                <th class="bold">
                  Installation
                </th>
                <td data-placement="top">
                  {{ getDetailSidebarComment.installationName }}
                </td>
              </tr>
              <tr>
                <th class="bold">
                  Message
                </th>
                <td
                  class="xmp"
                  data-placement="top"
                  v-html="getDetailSidebarComment.message"
                />
              </tr>
              <tr>
                <th class="bold">
                  Type
                </th>
                <td data-placement="top">
                  {{ getDetailSidebarComment.type }}
                </td>
              </tr>
              <tr>
                <th class="bold">
                  Recipient
                </th>
                <td data-placement="top">
                  {{ getDetailSidebarComment.recipient }}
                </td>
              </tr>
              <tr>
                <th class="bold">
                  From
                </th>
                <td data-placement="top">
                  {{ getDetailSidebarComment.userName }}
                </td>
              </tr>
              <tr>
                <th class="bold">
                  Read
                </th>
                <td data-placement="top">
                  <font-awesome-icon
                    v-if="getDetailSidebarComment.read"
                    class="gray"
                    icon="envelope-open"
                  />
                  <font-awesome-icon
                    v-else
                    icon="envelope"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </Sidebar>
    </template>
  </Portlet>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import { mapGetters } from 'vuex';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "HistoryListOperation",
  components: {
    Sidebar
  },
  mixins: [
    dateTimeMixin,
    kendoGridMixin,
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    noteId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      showAllColumns: true,
      historyComments: null,
      noteFilter: true,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "message",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_sort: [
        {
          "field": "created",
          "dir": "desc"
        }
      ],
      kgm_columns: [
        {
          field: 'created',
          filterable: false,
          filter: 'date',
          title: 'Created',
          cell: 'dateTemplate',
          width: '125px'
        },
        {
          field: 'message',
          filterable: true,
          filter: 'text',
          title: 'Message',
          cell: 'preTemplate',
          filterCell: "messageFilter",
          hideOn: ['xsDown']
        },
        {
          field: 'recipient',
          filterable: true,
          filter: 'text',
          title: 'Recipient',
          filterCell: "recipientFilter",
          hideOn: ['mdDown']
        },
        {
          field: 'userName',
          filterable: true,
          filter: 'text',
          title: 'User',
          width: '150px',
          hideOn: ['xxlDown']
        },
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: 'Type',
          width: '100px',
          hideOn: ['mdDown'],
          filterCell: "typeFilter"
        },
        {
          filterable: false,
          title: 'Details',
          cell: 'detailTemplate',
          width: '100px'
        }
      ],
      detailSidebarIsVisible: false,
      detailSidebarIndex: null
    }
  },
  computed: {
    ...mapGetters([
      'store_getReadNoteLength'
    ]),
    getDetailSidebarComment () {
      return this.historyComments.find((comment, index) => index == this.detailSidebarIndex);
    },
    preparedHistoryComments: function () {
      if (this.historyComments == null) {
        return;
      }
      return this.historyComments.map(function (o) { return {
        created: o.created,
        installationId: o.installationId,
        installationName: o.installationName,
        message: o.message ? o.message.replace(/\n/g, "<br />") : '',
        userName: o.userName,
        type: o.type,
        recipient: o.recipient,
        id: o.id,
        read: o.read
      }});
    },
    commentsAvailable: function () {
      if (typeof this.historyComments != 'undefined' && this.historyComments.length > 0) return true;
      return false;
    },
    historyTypes: function () {
      if (!this.historyComments) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let historyTypes = Array.from(new Set(this.historyComments.map(o=>o.type)));
      //Remove null values
      historyTypes = historyTypes.filter(function (e) {return e});
      return historyTypes.sort();
    }
  },
  watch: {
    noteId () {
      this.openDetailSidebarWithIndex(this.noteId);
    }
},
  created () {
    this.getHistoryEntries();
    this.$eventBus.$on('openDetailSidebar', this.openDetailSidebar);
  },
  mounted () {
    this.$eventBus.$on('refreshHistory', this.eventListener);
  },
  beforeDestroy () {
    this.$eventBus.$off('refreshHistory', this.eventListener);
    this.$eventBus.$off('openDetailSidebar', this.openDetailSidebar);
  },
  methods: {
    changeFilter () {
      this.noteFilter = !!this.noteFilter;
      this.getHistoryEntries();
    },
    show () {
      this.$emit("show");
    },
    closeDetailSidebar () {
      this.detailSidebarIsVisible = false;
      this.detailSidebarIndex = null;
    },
    openDetailSidebar (value) {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      if(!value.read && email == value.recipient) {
        this.openDetailSidebarWithIndex(value.id);
      }
      else {
        this.historyComments.forEach((comment, index) => {
          if (comment.created == value.created) {
            this.detailSidebarIndex = index;
            return true;
          }
          return false;
        });
        this.detailSidebarIsVisible = true;
      }
    },
    openDetailSidebarWithIndex (value) {
      this.historyComments.forEach((comment, index) => {
        if (comment.id == value) {
          this.detailSidebarIndex = index;
          if(!comment.read) {
            this.setRead(comment.id);
            comment.read = true;
            if(this.store_getReadNoteLength && this.store_getReadNoteLength > 0) {
              this.$store.commit('store_setReadNoteLength', this.store_getReadNoteLength - 1);
            }
          }
          return true;
        }
        return false;
      });
      this.detailSidebarIsVisible = true;
    },
    setRead (id) {
      this.axios.post(`/Installation/SetNoteReadFlag?id=${ id }`);
    },
    eventListener () {
      this.getHistoryEntries();
    },
    // GET INSTALLATION COMMENTS gets the data from the historyComments-Database
    getHistoryEntries () {
      this.loading = true;
      let filter = {
        InstallationIds: [ this.installationId ],
        Limit: 100,
        noteFilter: this.noteFilter
      };
      this.axios.post('/Installation/GetInstallationComments', filter)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.historyComments = response.data;
          if(this.noteId) {
            this.openDetailSidebarWithIndex(parseInt(this.noteId));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.xmp{
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
